export const exchange = {
  name: 'exchangeMain',
  path: '/exchangeMain',
  component: () => import('@/views_mobile/member/exchange'),
  redirect: {
    name: 'exchange'
  },
  meta: {
    layout: 'mobile-default',
    text: 'exchange',
    gnb: false,
    gnbMenu: 'exchange'
  },
  children: [
    {
      name: 'coinExchange',
      path: '/coinExchange',
      component: () => import('@/views_mobile/member/exchange/coinExchange'),
      meta: {
        layout: 'mobile-default',
        text: '환전',
        gnb: false,
        gnbMenu: 'coinExchange'
      }
    },
    {
      name: 'exchange',
      path: '/exchange',
      props: true,
      component: () => import('@/views_mobile/member/exchange/exchange'),
      meta: {
        layout: 'mobile-default',
        text: '환전',
        gnb: false,
        gnbMenu: 'exchange'
      }
    }
  ]
}
