export const money = {
  name: 'moneyMain',
  path: '/moneyMain',
  component: () => import('@/views/member/money'),
  redirect: {
    name: 'charge'
  },
  meta: {
    layout: 'default',
    text: '입출금',
    gnb: false
  },
  children: [
    {
      name: 'coinCharge',
      path: '/coinCharge',
      component: () => import('@/views/member/money/coinCharge'),
      meta: {
        layout: 'default',
        text: 'front.cash.nav1',
        gnb: false,
        gnbSub: false,
        lnbClass: 'csmenu16',
        lnb: false,
        lnbChildren: false
      }
    },
    {
      name: 'charge',
      path: '/charge',
      component: () => import('@/views/member/money/charge'),
      meta: {
        layout: 'default',
        text: 'front.cash.nav1',
        gnb: false,
        gnbSub: true,
        lnbClass: 'csmenu16',
        lnb: true,
        lnbChildren: false
      }
    },
    {
      name: 'coinExchange',
      path: '/coinExchange',
      component: () => import('@/views/member/money/coinExchange'),
      meta: {
        layout: 'default',
        text: 'front.cash.nav2',
        gnb: false,
        lnbClass: 'csmenu17',
        lnb: false,
        lnbChildren: false
      }
    },
    {
      name: 'exchange',
      path: '/exchange',
      component: () => import('@/views/member/money/exchange'),
      meta: {
        layout: 'default',
        text: 'front.cash.nav2',
        gnb: false,
        lnbClass: 'csmenu17',
        lnb: true,
        lnbChildren: false
      }
    }
  ]
}
